import { types, getParent, getEnv, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import UserOrganisation, {
  fragments as userOrganisationFragments,
} from "./UserOrganisation";

import DateTime from "./DateTime";

import { transformer } from "../../helpers";

export const fragments = {
  fullDetails: gql`
    fragment UserFullDetails on User {
      id
      firstName
      lastName
      email
      phone
      status
      lockedAt
      lockedReason
      isPasswordChangeRequired
      organisation {
        ...UserOrganisationFullDetails
      }
      createdAt
      updatedAt
    }
    ${userOrganisationFragments.fullDetails}
  `,
};

export default types
  .model("User", {
    id: types.identifier,
    organisation: types.maybeNull(UserOrganisation),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    status: types.enumeration("Status", [
      "NEW",
      "ACTIVE",
      "LOCKED",
      "INACTIVE",
    ]),
    lockedAt: types.maybeNull(DateTime),
    lockedReason: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    password: types.maybeNull(types.string),
    isPasswordChangeRequired: types.boolean,
    createdAt: DateTime,
    updatedAt: types.maybeNull(DateTime),
  })
  .views(self => ({
    get parent() {
      return getParent(self);
    },
  }))
  .actions(self => {
    return {
      setOrganisation: value => {
        self.organisation = value;
        return self;
      },

      setFirstName: value => {
        self.firstName = value;
        return self;
      },

      setLastName: value => {
        self.lastName = value;
        return self;
      },

      setEmail: value => {
        self.email = value;
        return self;
      },

      setPhone: value => {
        self.phone = value;
        return self;
      },

      setStatus: value => {
        self.status = value;
        return self;
      },

      setPassword: value => {
        self.password = value;
        return self;
      },

      setIsPasswordChangeRequired: value => {
        self.isPasswordChangeRequired = Boolean(value);
        return self;
      },

      setLockedReason: value => {
        self.lockedReason = value;
        return self;
      },

      save: flow(function* save() {
        // @todo this gets called on a clone so outside scope apolloClient is not available
        const { apolloClient } = getEnv(self);
        const params = {
          id: self.id,
          firstName: self.firstName,
          lastName: self.lastName,
          email: self.email,
          phone: self.phone,
          isPasswordChangeRequired: self.isPasswordChangeRequired,
        };

        if (self.password !== null) {
          params.password = self.password;
        }

        yield apolloClient.mutate({
          mutation: gql`
          mutation updateUser {
            updateUser(input: {
                ${transformer(params)}
              }) {
                 id
              }
          }
      `,
        });
      }),
    };
  });
