import React, { Fragment, useState, useEffect } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { Link } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/LockRounded";
import UnlockIcon from "@mui/icons-material/LockOpenRounded";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import MUIDataTable from "mui-datatables";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import MuiDatatablesFilters from "../../components/Filter/MuiDatatablesFilters";
import PositiveAction from "../../components/Button/PositiveAction";
import NegativeAction from "../../components/Button/NegativeAction";
import AddAction from "../../components/Button/AddAction";

const ListComponent = ({ appStore }) => {
  const [tableData, setTableData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [statusSelected, setStatusSelected] = useState(null);
  const [error, setError] = useState("");
  const [lockedReason, setLockedReason] = useState("");
  const [errorLockedReason, setErrorLockedReason] = useState("");

  useEffect(() => {
    appStore.userStore
      .findAll()
      .then(results => {
        setTableData([...results.values()]);
        appStore.setLoading(false);
      })
      .catch(error => {
        appStore.log.error(error);
        setError(error.message);
        appStore.setLoading(false);
      });
  }, []);

  const handleLockReasonChange = event => {
    setLockedReason(event.target.value);
    setErrorLockedReason("");
    setError("");
  };

  const columns = [
    {
      label: "First name",
      name: "firstName",
      options: {
        filter: false,
        sort: true,
        sortOrder: "asc",
      },
    },
    {
      label: "Last name",
      name: "lastName",
      options: { filter: false, sort: true },
    },
    { label: "Email", name: "email", options: { filter: false, sort: true } },
    {
      label: "Status",
      name: "status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (status, tableMeta) => {
          const currentRow = tableMeta.tableData[tableMeta.rowIndex];
          return (
            <>
              <Chip
                label={status}
                color={status === "LOCKED" ? "secondary" : "primary"}
                variant="outlined"
                data-testid="user-status-chip"
              />
              {currentRow?.lockedReason ? (
                <>
                  <br />
                  {currentRow.lockedReason}
                </>
              ) : (
                ""
              )}
            </>
          );
        },
      },
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, tableMeta) => {
          const currentRow = tableMeta.tableData[tableMeta.rowIndex];

          return (
            <Fragment key={currentRow.id}>
              <IconButton
                onClick={() => handleStatusClick(currentRow)}
                size="medium"
                data-testid="status-toggle-button"
              >
                {currentRow.status === "LOCKED" ? <UnlockIcon /> : <LockIcon />}
              </IconButton>
              <Link to={`/app/users/${currentRow.id}`}>
                <IconButton size="medium">
                  <EditIcon />
                </IconButton>
              </Link>
              <IconButton
                onClick={() => handleDeleteClick(currentRow)}
                size="medium"
              >
                <CancelIcon />
              </IconButton>
            </Fragment>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    rowsPerPage: 100,
    selectableRows: "none",
    search: false,
  };

  const handleDeleteClick = item => {
    setSelected(item);
  };

  const handleDeleteYes = () => {
    appStore.setLoading();

    const updatedTableData = tableData.filter(row => row.id !== selected.id);

    appStore.userStore
      .delete(selected.id)
      .then(() => {
        setTableData(updatedTableData);
        handleDeleteClick(null);
        appStore.setLoading(false);
      })
      .catch(error => {
        appStore.log.error(error);
        setError(error.message.replace("GraphQL error: ", ""));
        appStore.setLoading(false);
      });
  };

  const handleStatusClick = item => {
    setStatusSelected(item);
  };

  const handleStatusYes = () => {
    appStore.setLoading();

    const currentUserId = statusSelected.id;
    const currentLockedReason = lockedReason;

    appStore.userStore
      .lockUser(currentUserId, currentLockedReason)
      .then(() => {
        appStore.setLoading(false);
        const statusToDisplay =
          statusSelected.status === "ACTIVE" ? "LOCKED" : "ACTIVE";
        const lockedReasonToDisplay =
          statusToDisplay === "ACTIVE" ? "" : currentLockedReason;

        statusSelected.setStatus(statusToDisplay);
        statusSelected.setLockedReason(lockedReasonToDisplay);
        setStatusSelected(null);
      })
      .catch(error => {
        appStore.log.error(error);
        setError(error.message.replace("GraphQL error: ", ""));
        appStore.setLoading(false);
      });
  };

  const handleModalNo = () => {
    setError("");
    setSelected(null);
    handleStatusClick(null);
  };

  return (
    <ErrorBoundary>
      <Page title="Users">
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid container item xs={12} justifyContent="end" mt={3}>
              <Link to="/app/users/add">
                <AddAction buttonText="Add user" />
              </Link>
            </Grid>
            <Grid item xs={12}>
              <section>
                {selected && (
                  <Dialog open onClose={() => {}}>
                    <DialogTitle>Delete user</DialogTitle>
                    <DialogContent>
                      {!!error && (
                        <>
                          <FormHelperText error>{error}</FormHelperText>
                          <br />
                        </>
                      )}
                      <DialogContentText>
                        Are you sure you want to delete this user?
                        <br />
                        <b>
                          {selected.firstName} {selected.lastName}
                        </b>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <NegativeAction buttonText="No" onClick={handleModalNo} />
                      <PositiveAction
                        buttonText="Yes"
                        onClick={handleDeleteYes}
                      />
                    </DialogActions>
                  </Dialog>
                )}
                {statusSelected && (
                  <Dialog open onClose={() => {}}>
                    <DialogTitle>
                      {statusSelected.status === "LOCKED" ? "Unlock " : "Lock "}
                      user
                    </DialogTitle>
                    <DialogContent data-testid="status-toggle-modal-content">
                      {!!error && (
                        <>
                          <FormHelperText error>{error}</FormHelperText>
                          <br />
                        </>
                      )}
                      <DialogContentText>
                        Are you sure you want to{" "}
                        {statusSelected.status === "LOCKED" ? (
                          <b>unlock</b>
                        ) : (
                          <b>lock</b>
                        )}{" "}
                        this user?
                        <br />
                        <b>
                          {statusSelected.firstName} {statusSelected.lastName}
                        </b>
                      </DialogContentText>
                      {statusSelected.status !== "LOCKED" && (
                        <TextField
                          variant="standard"
                          fullWidth
                          autoComplete="off"
                          label="Locking reason "
                          value={lockedReason}
                          error={!!errorLockedReason}
                          helperText={errorLockedReason}
                          onChange={handleLockReasonChange}
                        />
                      )}
                    </DialogContent>
                    <DialogActions data-testid="status-toggle-modal-actions">
                      <NegativeAction buttonText="No" onClick={handleModalNo} />
                      <PositiveAction
                        buttonText="Yes"
                        onClick={handleStatusYes}
                        testId="confirm-status-toggle-button"
                      />
                    </DialogActions>
                  </Dialog>
                )}
                {!!tableData.length && (
                  <MUIDataTable
                    data={tableData}
                    columns={columns}
                    options={options}
                    components={{
                      TableFilterList: MuiDatatablesFilters,
                    }}
                  />
                )}
              </section>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

ListComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const List = inject("appStore")(observer(ListComponent));

export default List;
