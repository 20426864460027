import React, { useCallback, useEffect, useState } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import find from "lodash/find";

import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import DataTable from "../../components/Datatable/Datatable";
import ErrorBoundary from "../../components/ErrorBoundary";
import NegativeAction from "../../components/Button/NegativeAction";
import Page from "../../components/Page";
import PositiveAction from "../../components/Button/PositiveAction";
import PriorityOrganiser from "./components/PriorityOrganiser";
import AddAction from "../../components/Button/AddAction";
import Switch from "../../components/Switch";
import IconButton from "../../components/Button/IconButton";

const ListComponent = ({ appStore }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [siteData, setSiteData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [tableOptions, setTableOptions] = useState({
    rowsPerPage: 10,
    filter: { active: true },
    search: null,
    sort: { sortCol: "priority", direction: "asc" },
  });
  const [rowCount, setRowCount] = useState(0);
  const [columnFilterLists, setColumnFilterLists] = useState({
    active: ["Yes"],
  });

  const [priorityModal, setPriorityModal] = useState(false);
  const [priorityOrder, setPriorityOrder] = useState([]);

  useEffect(() => {
    appStore.siteStore
      .findAll()
      .then(() => {
        setSiteData(appStore.siteStore.basicSiteInformation);
      })
      .catch(error => {
        appStore.setLoading(false);
        appStore.log.error(error);
        navigate("/app/404");
      });
  }, [appStore]);

  const findAllPromotions = useCallback(() => {
    if (appStore.isLoading === false) {
      appStore.setLoading(true);
    }

    appStore.promotionStore
      .findAll()
      .then(() => {
        const { filter, rowsPerPage, search, sort } = tableOptions;

        setTableData(
          appStore.promotionStore.paginate({
            limit: rowsPerPage,
            offset: rowsPerPage * currentPage,
            sort,
            filter,
            search,
          }),
        );
        appStore.setLoading(false);
      })
      .catch(error => {
        appStore.setLoading(false);
        appStore.log.error(error);
        navigate("/app/404");
      });
  }, [appStore, currentPage, tableOptions]);

  useEffect(findAllPromotions, [appStore, tableOptions, findAllPromotions]);

  useEffect(() => {
    setRowCount(appStore.promotionStore.count);
  }, [appStore.promotionStore.count]);

  const handleChangeRowsPerPage = numberOfRows => {
    setTableOptions({
      ...tableOptions,
      rowsPerPage: parseInt(numberOfRows, 10),
    });

    setCurrentPage(0);
  };

  const handleSearchChange = searchText => {
    setTableOptions({
      ...tableOptions,
      search: searchText,
    });

    setCurrentPage(0);
  };

  const handleUpdateFilterState = (applyNewFilters, columns) => {
    const newFilters = applyNewFilters();
    const filtersToApply = {};
    const columnFiltersToUpdate = columnFilterLists;

    newFilters.forEach((filter, index) => {
      const header = columns[index];
      const filterValue = filter[0];

      if (header.name && filterValue) {
        switch (header.name) {
          case "active":
            filtersToApply[header.name] = filterValue === "Yes";
            columnFiltersToUpdate[header.name] = [filterValue];
            break;
          default:
            filtersToApply[header.name] = filterValue;
            break;
        }
      } else if (header.name && !filterValue) {
        columnFiltersToUpdate[header.name] = [];
      }
    });

    setTableOptions({
      ...tableOptions,
      filter: filtersToApply,
    });
    setColumnFilterLists(columnFiltersToUpdate);
  };

  const handleSortChange = (changedColumn, direction) => {
    setTableOptions({
      ...tableOptions,
      sort: { sortCol: changedColumn, direction },
    });
  };

  const handlePriorityClick = () => {
    setPriorityModal(true);
    setPriorityOrder(appStore.promotionStore.sortByPriority);
  };

  const renderPriorityModal = () => {
    const handleClose = () => {
      setPriorityModal(false);
      findAllPromotions();
      // timeout for animation
      setTimeout(() => {
        setPriorityOrder([]);
      }, 500);
    };

    const handleSubmit = () => {
      priorityOrder.forEach((item, index) => {
        item.setPriority(index);
      });

      appStore.setLoading(true);
      appStore.promotionStore
        .updatePriorities(priorityOrder)
        .then(() => {
          appStore.setLoading(false);
          handleClose();
        })
        .catch(error => {
          appStore.setLoading(false);
          appStore.log.error(error);
          navigate("/app/404");
        });
    };

    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        open={priorityModal}
      >
        <DialogTitle>Drag and drop promotions in order of priority</DialogTitle>
        <DialogContent>
          <PriorityOrganiser
            promotions={priorityOrder}
            update={promotions => setPriorityOrder(promotions)}
          />
        </DialogContent>
        <DialogActions>
          <NegativeAction buttonText="Cancel" onClick={handleClose} />
          <PositiveAction buttonText="Submit" onClick={handleSubmit} />
        </DialogActions>
      </Dialog>
    );
  };

  const matchSiteIdToStoreSite = siteId => {
    const existingSite = find(siteData, { id: siteId });
    if (!existingSite) {
      return {
        id: "error",
        name: "Site Unavailable",
        active: false,
        error: true,
      };
    }
    return existingSite;
  };

  const generateSiteString = (value, listFormat = false) => {
    let itemSiteData = {};

    try {
      itemSiteData = JSON.parse(value).sites;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }

    if (!itemSiteData) {
      return "No Sites";
    }

    if (itemSiteData.allSites) {
      itemSiteData = siteData;
    } else {
      itemSiteData = Object.keys(itemSiteData).map(siteId =>
        matchSiteIdToStoreSite(siteId),
      );
    }

    if (!listFormat) {
      itemSiteData = itemSiteData.filter(site => site.active);

      switch (itemSiteData.length) {
        case 0:
          return "No Sites";
        case 1:
          return itemSiteData[0].name;
        case siteData.filter(site => site.active).length:
          return "All Sites";
        default:
          return itemSiteData.length;
      }
    } else {
      return itemSiteData.map(
        item =>
          `${item.name} ${
            item.active !== true && !item.error ? "(Inactive Site)" : ""
          }`,
      );
    }
  };

  const toggleActiveStatus = (rowIndex, currentStatus) => {
    const updatedStatus = !currentStatus;
    // update mobx cached data
    tableData[rowIndex].setActive(updatedStatus).save();
    // trigger a re-render so that items are filtered as expected
    setTableOptions({ ...tableOptions });
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "startAt",
      label: "Start Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => moment(value).format("lll"),
      },
    },
    {
      name: "endAt",
      label: "End Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: value =>
          value ? moment(value).format("lll") : "None",
      },
    },
    {
      name: "priority",
      label: "Priority",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "rules",
      label: "Sites",
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => generateSiteString(value),
      },
    },
    {
      name: "active",
      label: "Active",
      options: {
        filter: true,
        filterList: columnFilterLists.active,
        filterOptions: {
          names: ["Yes", "No"],
        },
        customFilterListOptions: { render: value => `Active: ${value}` },
        customBodyRender: (value, { rowIndex }) => {
          return (
            <Switch
              active={value}
              colour="success"
              onChange={() => toggleActiveStatus(rowIndex, value)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: value => (
          <Link to={`/app/promotions/${value}`}>
            <IconButton icon={<EditIcon />} testId="edit-promotion-button" />
          </Link>
        ),
      },
    },
  ];

  const handleRenderExpandableRow = (rowData, rowMeta) => {
    const siteString = generateSiteString(
      tableData[rowMeta.dataIndex].rules,
      true,
    );

    return (
      <TableRow>
        <TableCell colSpan={rowData.length + 1}>
          Assigned Sites:&nbsp;
          <List>
            {Array.isArray(siteString) && siteString.length > 0 ? (
              siteString.map(item => <ListItem key={item}>{item}</ListItem>)
            ) : (
              <ListItem>None</ListItem>
            )}
          </List>
        </TableCell>
      </TableRow>
    );
  };

  const handleRowClick = () => {};

  const onFilterChange = (column, filterList, type) => {
    if (type === "chip") {
      const applyNewFilters = () => filterList;
      handleUpdateFilterState(applyNewFilters, columns);
    }
  };

  const customFilterDialogFooter = (currentFilterList, applyNewFilters) => (
    <div style={{ marginTop: "40px" }}>
      <PositiveAction
        buttonText="Apply Filters"
        onClick={() => handleUpdateFilterState(applyNewFilters, columns)}
      />
    </div>
  );

  return (
    <ErrorBoundary>
      {renderPriorityModal()}
      <Page title="Promotions">
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <Grid item>
                <Typography variant="h2">Promotions</Typography>
              </Grid>
              <Grid container item xs={6} justifyContent="end">
                <Grid item>
                  <Link to="/app/promotions/add">
                    <AddAction buttonText="Add new promotion" />
                  </Link>
                </Grid>
                <Grid item ml={1}>
                  <PositiveAction
                    buttonText="Set priority"
                    onClick={handlePriorityClick}
                    disabled={priorityOrder.length > 0}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DataTable
                tableData={tableData}
                columns={columns}
                tableOptions={tableOptions}
                totalRowCount={rowCount}
                handleRenderExpandableRow={handleRenderExpandableRow}
                handleRowClick={handleRowClick}
                handleChangePage={curPage => setCurrentPage(curPage)}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleSortChange={handleSortChange}
                handleSearchChange={handleSearchChange}
                handleFilterChange={onFilterChange}
                handleRenderCustomFilterDialogFooter={customFilterDialogFooter}
                page={currentPage}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};
ListComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const PromotionList = inject("appStore")(observer(ListComponent));

export default PromotionList;
